import { ToastContainer as RTToastContainer } from 'react-toastify';

export default function ToastContainer() {
  return (
    <RTToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      closeOnClick
      pauseOnHover
      draggable
      theme={'light'}
      className="font-body font-bold"
    />
  );
}
