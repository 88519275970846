/* eslint-disable no-param-reassign */

/**
 * This slice is for any theme-specific state needed globally.
 */

import { createSlice } from '@reduxjs/toolkit';

export type ThemeState = {
  /** The profile view that is currently open. 'null' means it is closed. */
  activeProfileView: string | null;
  themeFonts: { heading: string; body: string; } | null;
};

const initialState: ThemeState = {
  activeProfileView: null,
  themeFonts: null,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setActiveProfileView(state, action) {
      state.activeProfileView = action.payload;
    },
    setThemeFonts(state, action){
      state.themeFonts = action.payload;
    }
  },
});

export const { setActiveProfileView, setThemeFonts } = themeSlice.actions;

export const selectActiveProfileView = (state: { theme: ThemeState }) =>
  state.theme?.activeProfileView;

export const selectThemeFonts = (state: { theme: ThemeState}) =>
  state.theme?.themeFonts;

export default themeSlice.reducer;
