'use client';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore - due to module type of library
import camelcaseKeys from 'camelcase-keys';
import { setAgency, setThemePreferences } from '@homeflow/next/state';
import { SerializableAgency } from '@/serializers/agency';

export default function InitCompany({
  agency,
}: {
  agency: SerializableAgency;
}): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (agency.preferences) dispatch(setThemePreferences(camelcaseKeys(agency.preferences)));

    dispatch(setAgency(agency));
  }, [dispatch, agency]);

  return null;
}
