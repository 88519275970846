'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { setHostCookie } from '@/app/actions';

/**
 * Sets the _host cookie for local development purposes.
 * Setting cookies can only be done via a server action
 * called from a client component (or in route handlers)
 */
export default function HostCookieSetter(): null {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'development') return;

    const host = searchParams.get('_host');
    if (host) setHostCookie(host);
  }, [searchParams]);

  return null;
}
