import {
  SavedSearch,
  setCurrentUser,
  setSavedProperties,
  setSavedSearches,
} from '@homeflow/next/state';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import createLocalHFDB from '@homeflow/next/indexeddb/create-local-hf-db';
import getAllFromStore from '@homeflow/next/indexeddb/get-all-from-store';

export default function InitUser() {
  const { status, data: session } = useSession();
  const dispatch = useDispatch();

  useEffect(() => {
    if (status !== 'authenticated' || !session?.encryptedCookieToken) {
      // Create indexeddb now so all stores are avaialable and we dont need to muck around with versions
      // Any new stores can be added in indexeddb/create-local-hf-db
      createLocalHFDB();

      // populate state with saved properties/searches from local indexedDB
      async function populateStateFromIdb() {
        const savedSearches = await getAllFromStore('saved_searches');
        const savedProperties = await getAllFromStore('saved_properties');

        dispatch(setSavedSearches(savedSearches));
        dispatch(setSavedProperties(savedProperties));
      }

      populateStateFromIdb();

      return;
    }

    // verify the session
    async function getUser(): Promise<void> {
      let user;

      try {
        const res = await fetch(`/api/user/${session?.encryptedCookieToken}`);

        user = await res.json();
      } catch (e) {
        console.error(e);
      }

      if (!user) return;

      // We need to add keys to saved searches for adding/removing from redux
      const savedSearches = user.savedSearches?.map((s: SavedSearch) => ({
        ...s,
        key: s.id,
      }));

      dispatch(setCurrentUser(user));
      dispatch(setSavedSearches(savedSearches));

      const savedPropertyIds = user.savedProperties?.sales
        .map((prop: { property_id: number }) => prop.property_id)
        .concat(
          user.savedProperties.lettings.map((prop: { property_id: number }) => prop.property_id)
        );

      dispatch(setSavedProperties(savedPropertyIds));
    }

    getUser();
  }, [dispatch, status, session?.encryptedCookieToken]);

  return null;
}
