'use client';

import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';
import StyledJsxRegistry from '@/components/styled-jsx-registry.component';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import store from '@/store/store';

export default function Providers({
  children,
  session, // session must be passed from getServerSession called in a server component
  admin = false,
}: PropsWithChildren<{ session?: Session | null; admin?: boolean }>) {
  const [recaptchaKey, setRecaptchaKey] = useState('');
  const recaptchaCleanupRef = useRef(false);

  const handleFocusIn = useCallback((event: FocusEvent) => {
    const nodeName = (event.target as HTMLElement).nodeName;

    // if focus has occured on a form element, add Recaptcha
    if (['INPUT', 'SELECT'].includes(nodeName)) {
      setRecaptchaKey(process.env.NEXT_PUBLIC_CAPTCHA_VERIFY_API_SITE_KEY!);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('focusin', handleFocusIn);

    return () => {
      document.body.removeEventListener('focusin', handleFocusIn);
    };
  }, [handleFocusIn]);

  useEffect(() => {
    // don't set Recaptcha key in admin as we are behind auth so don't need Recaptcha
    if (admin) return;
    // Cleanup event listeners once recaptcha key set
    if (recaptchaKey && !recaptchaCleanupRef.current) {
      document.body.removeEventListener('focusin', handleFocusIn);
      recaptchaCleanupRef.current = true;
    }
  }, [recaptchaKey, handleFocusIn, admin]);

  return (
    <Provider store={store}>
      <StyledJsxRegistry>
        <SessionProvider session={session}>
          {admin ? (
            children
          ) : (
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
              {children}
            </GoogleReCaptchaProvider>
          )}
        </SessionProvider>
      </StyledJsxRegistry>
    </Provider>
  );
}
