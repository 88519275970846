import { configureStore } from '@reduxjs/toolkit';
import {
  searchReducer,
  propertiesReducer,
  userReducer,
  companyReducer,
  configReducer,
} from '@homeflow/next/state';

import themeReducer from './theme-slice';

const store = configureStore({
  reducer: {
    search: searchReducer,
    properties: propertiesReducer,
    user: userReducer,
    company: companyReducer,
    theme: themeReducer,
    config: configReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export default store;
